<template>
  <div class="container">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <div id="product__addcompany">
      <ul id="product__addcompany--list">
      <draggable v-model="sidebar" group="people" @start="drag=true" @end="drag=false">
          <li v-for="(c, i) in sidebar" v-bind:key="i" class="list-group-item" style="margin-bottom:3px;list-style:none;cursor:pointer;">
            <v-icon
              medium
              class="mr-2"
              title="Drag & Sort"
            >
              mdi-drag
            </v-icon>
            <span>
              {{ c.module_name }}
            </span>
          </li>
      </draggable>
      </ul>
      <v-row >
        <v-col cols="5" class="text-center">
    </v-col>
    <v-col cols="3" class="text-center">
      <v-btn
        small
        color="primary"
        @click="submitModules()"
      >
        Submit
      </v-btn>
    </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import draggable from 'vuedraggable'

export default {
  components: { Index, Buttons, Loading, draggable },
  data() {
    return {
      //////table data starts here////
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      sidebar:[]
      ///
    };
  },
    mounted() {
        // this.sidebar = this.$store.getters['Rawdata/getSidebar']

  },
  computed: {
  //pagination computed methods
  modules_temp: function() {
    if(this.panel_id == 0 && this.selected_parent_module == null){
      return this.modules
    }else if(this.panel_id == 0 && this.selected_parent_module != null){
      return this.modules.filter(e => e.parent_id == this.selected_parent_module)
    }
    else if(this.panel_id != 0 && this.selected_parent_module == null){
      return this.modules.filter(e => e.panel_id == this.panel_id)
    }
    else if(this.panel_id != 0 && this.selected_parent_module != null){
      return this.modules.filter(e => (e.panel_id == this.panel_id && e.parent_id == this.selected_parent_module))
    }
  }
},
 methods: {
    checkIfInselectedChildren(c, sc) {
      let res = false;
      if (sc.includes(c)) {
        res = true;
      }
      return res;
    },
    submitModules(){

    },
    validate() {
      this.$refs.form.validate();
    },
    get_parent_name(parent_id){
      let parent_name = ''
      this.modules.forEach((m)=>{
        if(m.id == parent_id){
          parent_name = m.name
        }
      })
      return parent_name
    },
    reset() {
      this.message1 = "";
      this.upmessage1 = "";
      // this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    submitModules(){
      let data = this;
      data.$swal
            .fire({
              title: "Are you sure ?",
              text:
                "This will update new module sorting",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Yes, Submit!",
              cancelButtonText: "No, cancel !",
              closeOnConfirm: false,
              closeOnCancel: false,
            })
            .then((result) => {
              if (result.isConfirmed) {
                data.isLoading = true;
                data.$fetch({ requiresAuth: true, operation : 'edit', vueScope: data })
                .put(data.$url("SIDEBAR_SORTING_UPDATE"), data.sidebar)
                .then((result) => {
                  data.isLoading = false;
                  if (result.data.statusCode == 200) {
                    this.$swal.fire({
                      icon: "success",
                      title: "Sorting Updated",
                      text: result.data.payload.message,
                    });
                  }
                  console.log(result);
                }).catch((error) => {
                  data.isLoading = false;
                  console.error(error);
                });

              } else if (result.isDenied) {
                data.$swal.fire(
                  "Cancelled",
                  "New Sorting was not updated!",
                  "error"
                );
              }
            });
    },
    checkIfOperationboxShowAble(operationId, mid){
      let result = false;
      let moduleData = this.modules;
      moduleData.forEach((m) =>{
        if(mid == m.id){
          if(m.children.length){
            m.children.forEach((c) => {
              if(c.id==operationId){
                result = true
                if(m.module_id==2){
                }
              }
            })
        }
        }

      }
      )
      return result
    },
    checkIfOperationboxCheckAble(operationId, mid){
      let result = false;
      let moduleData = this.modules;
      moduleData.forEach((m) =>{
        if(mid == m.id){
          if(m.selected_children.length){
            m.selected_children.forEach((c) => {
              if(c==operationId){
                result = "checked"
                if(m.module_id==2){
                }
              }
            })
        }
        }
      }
      )
      return result
    },
    checkIfAllOperationsSelected(moduleId) {
      let moudles = this.modules;
      let result = false
      if (moudles.length) {
        moudles.forEach((m) => {
          if (m.id === moduleId) {
            if (m.children.length == m.selected_children.length) {
              result = true
            }
          }
        });
      }
      return result
    },
    selectedCurrentModuleOperations(e, moduleId) {
      let moudles = this.modules;
      let moduleFound = 0;
      if (moudles.length) {
        // have some data
        moudles.forEach((m) => {
          if (m.id === moduleId) {
            if (m.children.length) {
              // if module have children
              if (e.target.checked) {
                m.selected_children = [];
                m.children.forEach((c)=>{
                  m.selected_children.push(c.id);
                })
                if (!m.selected_children.includes(childId)) {
                  m.selected_children.push(childId);
                }
              } else {
                m.selected_children = [];
              }
            }
          }
        });
      }
      //console.log(selected);
    },
    selectedChild(e, moduleId, childId) {
      let selected = this.modules;
      let moduleFound = 0;
      if (selected.length) {
        // have some data
        selected.forEach((m) => {
          if (m.id === moduleId) {
            if (m.selected_children.length) {
              // if module have children
              if (e.target.checked) {
                if (!m.selected_children.includes(childId)) {
                  m.selected_children.push(childId);
                }
              } else {
                m.selected_children.splice(
                  m.selected_children.indexOf(childId),
                  1
                );
              }
            } else {
              // no children
              if (e.target.checked) {
                m.selected_children.push(childId);
              }
            }
            moduleFound = moduleFound + 1;
          }
        });
        if (moduleFound == 0) {
          let module = {
            id: moduleId,
            selected_children: [childId],
          };
          selected.push(module);
        }
      } else {
        //no data
        let module = {
          id: moduleId,
          selected_children: [childId],
        };
        selected.push(module);
      }
      console.log(selected);
    },
    addPermissionData(e) {
      let data = this;
      let modules = data.modules;
      let permissionData = data.operationData;
      if (permissionData.length) {
        modules.forEach((m) => {
          permissionData.forEach((p) => {
            if (m.id == p.module_id) {
              if (!m.selected_children.includes(p.operation_id)) {
                m.selected_children.push(p.operation_id);
                m.mom_id.push(p.mom_id);
                m.module_id = p.module_id;
              }
            }
          });
        });
      }
    },
    selectedChild(e, moduleId, childId) {
      let selected = this.modules;
      let moduleFound = 0;
      if (selected.length) {
        // have some data
        selected.forEach((m) => {
          if (m.id === moduleId) {
            if (m.selected_children.length) {
              // if module have children
              if (e.target.checked) {
                if (!m.selected_children.includes(childId)) {
                  m.selected_children.push(childId);
                }
              } else {
                m.selected_children.splice(
                  m.selected_children.indexOf(childId),
                  1
                );
              }
            } else {
              // no children
              if (e.target.checked) {
                m.selected_children.push(childId);
              }
            }
            moduleFound = moduleFound + 1;
          }
        });
        if (moduleFound == 0) {
          let module = {
            id: moduleId,
            selected_children: [childId],
          };
          selected.push(module);
        }
      } else {
        //no data
        let module = {
          id: moduleId,
          selected_children: [childId],
        };
        selected.push(module);
      }
      console.log(selected);
    },
    submitPermissions(e) {
      e.preventDefault();
      let data = this;
      data.$swal
            .fire({
              title: "Are you sure ?",
              text:
                "This will update new module operations permissions",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Yes, Submit!",
              cancelButtonText: "No, cancel !",
              closeOnConfirm: false,
              closeOnCancel: false,
            })
            .then((result) => {
              if (result.isConfirmed) {
                data.isLoading = true;
                data.$fetch({ requiresAuth: true, operation : 'edit', vueScope: data })
                .put(data.$url("MODULE_OPERATIONS_DATA"), data.modules)
                .then((result) => {
                  data.isLoading = false;
                  if (result.data.statusCode == 200) {
                    this.$swal.fire({
                      icon: "success",
                      title: "Permission Updated",
                      text: result.data.payload.message,
                    });
                  }
                  console.log(result);
                }).catch((error) => {
                  data.isLoading = false;
                  console.error(error);
                });

              } else if (result.isDenied) {
                data.$swal.fire(
                  "Cancelled",
                  "File was not deleted!",
                  "error"
                );
              }
            });
    },
    selectAll: function () {
      let delaffinity = this.modules;
      if (this.allSelected == true) {
        if (Array.isArray(delaffinity) && delaffinity.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < delaffinity.length; i++) {

              this.deleteItems.push(delaffinity[i][0]);
              console.log("gsdhghshsjss");

          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
  watch: {
    '$store.state.Rawdata.sidebar': function(o) {
      if(o){
        //this.getResult(1);
        this.isLoading = false
        //this.sidebar = this.$store.getters['Rawdata/getSidebar']
        let check = this;
      const endpoint_url = this.$url("GET_MODULES") + "?id=0";
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(endpoint_url)
        .then((res) => {
          let modules = res.data.payload.modules;
          this.total = modules.length;
          let parent_modules = []
          if (Array.isArray(modules) && modules.length) {
            modules.forEach((md)=>{
              if(md.parent_id == 0){
                //   let tempobj = {
                //   value: md.module_id,
                //   text: md.module_name,
                // };
                if (md.parent_id == 0 && md.panel_id == 1) {
                  parent_modules.push(md);
                }
              }
            })
            let sorted = parent_modules.sort((a, b) => (a.module_sort > b.module_sort) ? 1 : -1)
            this.sidebar = sorted
          }
        });
        this.isChildModuleDdEditing = true
      }
    },
 },
};
</script>
<style  scoped>
/*
 Only custom marker for summary/details
 For cross browser compatible styling hide Firefox's marker by setting summary { display: block }
 and Chrome and Safari's marker by setting ::-webkit-details-marker {display: none;}
*/
summary {
  display: block;
  cursor: pointer;
  outline: 0;
}

summary::-webkit-details-marker {
  display: none;
}

.tree-nav__item {
  display: block;
  white-space: nowrap;
  color: #ccc;
  position: relative;
}
.tree-nav__item.is-expandable::before {
  content: "";
  height: 100%;
  left: 0.8rem;
  position: absolute;
  top: 2.4rem;
  height: calc(100% - 2.4rem);
}
.tree-nav__item .tree-nav__item {
  margin-left: 2.4rem;
}
.tree-nav__item.is-expandable[open] > .tree-nav__item-title::before {
  font-family: "ionicons";
  transform: rotate(180deg);
  content: "-";
}
.tree-nav__item.is-expandable > .tree-nav__item-title {
  padding-left: 2.4rem;
}
.tree-nav__item.is-expandable > .tree-nav__item-title::before {
  position: absolute;
  will-change: transform;
  transition: transform 300ms ease;
  font-family: "ionicons";
  color: #000;
  font-size: 1.1rem;
  content: "+";
  left: 0;
  display: inline-block;
  width: 1.6rem;
  text-align: center;
}

.tree-nav__item-title {
  cursor: pointer;
  display: block;
  outline: 0;
  color: #000;
  font-size: 1rem;
  line-height: 3.2rem;
}
.tree-nav__item-title .icon {
  display: inline;
  padding-left: 1.6rem;
  margin-right: 0.8rem;
  color: #666;
  font-size: 1.4rem;
  position: relative;
}
.tree-nav__item-title .icon::before {
  top: 0;
  position: absolute;
  left: 0;
  display: inline-block;
  width: 1.6rem;
  text-align: center;
}

.tree-nav__item-title::-webkit-details-marker {
  display: none;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
    background: #3f51b5!important;
}
</style>
